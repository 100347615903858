import "react-phone-number-input/style.css";

import "./EnquireWindow.scss";
import "./Adaptations.scss";

import CrossThinImage from "../../../static/images/icons/cross-thin.svg";

import ContactForm from "../contact-form/ContactForm";
import { useTranslation } from "react-i18next";

export default function EnquireWindow({
    closeFunc,
    setModalContent,
    enquireWindowContent,
    setEnquireWindowContent,
    pageClicked,
}) {
    const {
        lot,
        total_lot_size,
        lot_size_tsubo,
        forest_area,
        buildable_area,
        max_suggested_villa_floor_area,
        description,
        status,
        image,
        price,
    } = enquireWindowContent;

    const [t] = useTranslation();



    const buttonFunc = () => {
        setEnquireWindowContent(null);
        setModalContent(
            <ContactForm
                form_id="xvgoqjaa"
                lot={lot}
                total_lot_size={total_lot_size}
                forest_area={forest_area}
                buildable_area={buildable_area}
                max_suggested_villa_floor_area={max_suggested_villa_floor_area}
                image={image}
                status_of_lot={status}
                price={price}
            />
        );
    };

    return (
        <>
            <div className="enquire-window-wrapper-port">
                <div className="enquire-window">
                    <div className="contact-form-close" onClick={closeFunc}>
                        <img src={CrossThinImage} alt="cross-close" />
                    </div>

                    <div className="enquire-window-info">
                        <img src={image} alt="" />
                        <div className="lot-size-status-block">
                            <div className="lot-size-con">
                                <span className="lot-span">Lot {lot}</span>|
                                <span className="total-lot-size-span">
                                    {total_lot_size}sqm
                                </span>
                            </div>
                            <span
                                className={
                                    status === "available"
                                        ? "price"
                                        : "status-span"
                                }
                                style={{
                                    backgroundColor:
                                        status === "sold"
                                            ? "#40585A"
                                            : status === "held"
                                            ? "#99B874"
                                            : "",
                                    color: status === "sold" ? "#fff" : "",
                                }}
                            >
                                {status === "available" ? `¥${price.toLocaleString('en-US')}` : t(status) }
                            </span>
                        </div>
                        <div className="info-inner">
                            <div className="info-inner-left">
                                <div>{t("lot_size_sqm")}</div>
                                <div>{t("lot_size_tsubo")}</div>
                                <div>{t("buildable_area")}</div>
                            </div>
                            <div className="info-inner-right">
                                <div>{total_lot_size}</div>
                                <div>{lot_size_tsubo}</div>
                                <div>{buildable_area}</div>
                            </div>
                        </div>
                        {status === "available" || status === "phase-2" ? (
                            <button
                                className="contact-form-submit"
                                onClick={buttonFunc}
                            >
                                {t("enquire_now")}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <div className="enquire-window-wrapper">
                <div className="enquire-window-landscape">
                    <div className="contact-form-close" onClick={closeFunc}>
                        <img src={CrossThinImage} alt="cross-close" />
                    </div>

                    <div className="enquire-window-info">
                        <div className="lot-size-status-block">
                            <div className="lot-size-status-block-inner">
                                <span className="lot-span">Lot {lot}</span>|
                                <span className="total-lot-size-span">
                                    {total_lot_size}sqm
                                </span>
                                <span
                                    className={
                                        status === "available"
                                            ? "price"
                                            : "status-span"
                                    }
                                    style={{
                                        backgroundColor:
                                            status === "sold" ? "#40585A" : "",
                                    }}
                                >
                                    {status === "available" ? price : t(status)}
                                </span>
                            </div>

                            <div className="info-inner">
                                <div className="info-inner-left">
                                    <div>{t("lot_size_sqm")}</div>
                                    <div>{t("lot_size_tsubo")}</div>
                                    <div>{t("buildable_area")}</div>
                                </div>
                                <div className="info-inner-right">
                                    <div>{total_lot_size}</div>
                                    <div>{lot_size_tsubo}</div>
                                    <div>{buildable_area}</div>
                                </div>
                            </div>
                            {status === "available" || status === "phase-2" ? (
                                <button
                                    className="contact-form-submit"
                                    onClick={buttonFunc}
                                >
                                    {t("enquire_now")}
                                </button>
                            ) : (
                                <></>
                            )}
                        </div>
                        <img src={image} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}
