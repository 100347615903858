import { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import "./Main.scss";

import { content_animation } from "./animations";
import { buttons } from "../../data/menu/buttons";

import Overlay from "../../layouts/overlay/Overlay";

import Loading from "../../components/loading/Loading";
import Menu from "../../components/main/menu/Menu";
import Modal from "../../components/modal/Modal";
import EnquireWindow from "../../components/main/enquire-window/EnquireWindow";
import TopLogo from "../../components/main/menu/top-logo/TopLogo";
import MenuLogo from "../../components/main/menu/menu-logo/MenuLogo";

export const MainContext = createContext(null);

export default function Main() {
    const location = useLocation();

    const [content, setContent] = useState(null);
    const [contentChanged, setContentChanged] = useState(0);

    const [modalContent, setModalContent] = useState(null);
    const [enquireWindowContent, setEnquireWindowContent] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const changeContent = (component, data) => {
        if (content?.component !== component) {
            setContent({
                component: component,
                data: data,
            });
            setContentChanged((contentChanged) => contentChanged + 1);
        }
    };

    const [pageClicked, setPageClicked] = useState(0);

    const handlePageClick = () => {
        setPageClicked((pageClicked) => pageClicked + 1);
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    return (
        <div
            className="main"
            onMouseDownCapture={handlePageClick}
            onClickCapture={handlePageClick}
        >
            <Overlay zIndex={5}>
                <Menu
                    buttons={buttons}
                    content={content}
                    changeContent={changeContent}
                    contentClicked={pageClicked}
                    setEnquireWindowContent={setEnquireWindowContent}
                />
                {/* <MenuLogo /> */}
                <AnimatePresence>
                    {modalContent && (
                        <Modal closeFunc={() => setModalContent(null)}>
                            {modalContent}
                        </Modal>
                    )}
                    {enquireWindowContent && (
                        <EnquireWindow
                         closeFunc={() => setEnquireWindowContent(null)}
                         enquireWindowContent={enquireWindowContent}
                         setModalContent={setModalContent}
                         setEnquireWindowContent={setEnquireWindowContent}
                         pageClicked={pageClicked}
                        />
                    )}
                    {isLoading && <Loading isLoading={isLoading} />}
                </AnimatePresence>
            </Overlay>
            <MainContext.Provider
                value={[changeContent, setModalContent, pageClicked, setEnquireWindowContent, enquireWindowContent, activeIndex, setActiveIndex]}
            >
                <AnimatePresence
                    mode="wait"
                    // location={location}
                    // key={location.pathname}
                >
                    <Outlet />
                </AnimatePresence>
            </MainContext.Provider>
        </div>
    );
}
