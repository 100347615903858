import { translationsEn } from "./en";
import { translationsJp } from "./jp";
import { translationsCh1 } from "./ch1";
import { translationsCh2 } from "./ch2";

export const resources = {
    en: { translation: translationsEn },
    jp: { translation: translationsJp },
    ch1: { translation: translationsCh1 },
    ch2: { translation: translationsCh2 },
};
