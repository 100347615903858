export const translationsCh2 = {
    //no translation needed
    haru_no_taki: "春之瀑布",
    niseko_village_golf_course: "二世谷高尔夫球场",
    ace_gondola: "Ace 贡多拉",
    hirafu_village: "比罗夫村",
    kutchan_town: "俱知安镇",

    back: "往前",
    lot_size_sqm: "土地面积（平方米）",
    lot_size_tsubo: "土地面积（坪）",
    buildable_area: "可建筑面积",
    sold: "已售出",
    held: "保留",
    available: "销售中",
    "phase-2": "第二期",
    english: "English",
    japanese: "日本語",
    chinese_traditional: "中文（繁體）",
    chinese_simplified: "中文（简体）",
    enquire_now: "咨询",
    full_name: "名字",
    email: "电子邮件",
    phone_number: "电话号码",
    country: "国籍",
    send: "寄出",
    total_lot_size: "土地大小",
    forest_area: "森林面积",
    max_villa_floor_area: "最大别墅建筑面积",

};