import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Reel from "../../../../components/main/reel/Reel";
import Frame from "../../../../components/main/frame/Frame";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Main";

import "./MasterPlan.scss";
import "./Adaptations.scss";
import { masterPlanStops } from "./stops";

import Zone5 from "../golf-estates/GolfEstates";
import Buraydah from "../buraydah/Buraydah";
import BackButton from "../../../../components/main/menu/back-button/BackButton";
import ServicesButton from "../../../../components/main/menu/services/ServicesButton";
import { TopLogoNisekoGolfEstates } from "../../../../components/main/menu/top-logo/TopLogo";

import startFrame from "../../../../static/images/contents/master-plan/renders/1/77.jpg";
import startFrameJp from "../../../../static/images/contents/master-plan/renders/1/77_jp.jpg";
import startFrameCh1 from "../../../../static/images/contents/master-plan/renders/1/77_ch1.jpg";
import startFrameCh2 from "../../../../static/images/contents/master-plan/renders/1/77_ch2.jpg";

import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";

import { ReactComponent as Zone5_21 } from "../../../../static/images/contents/master-plan/polygons/21.svg";

export default function MasterPlan() {
    
    const navigate = useNavigate();
    const location = useLocation();

    const languageCode = location.pathname.split('/')[1];

    const getLanguageFrame = () => {
        switch (languageCode) {
            case "jp":
                return startFrameJp;
            case "ch1":
                return startFrameCh1;
            case "ch2":
                return startFrameCh2;
            default:
                return startFrame;
        }
    };

    return (
        <div className="master-plan" style={{ width: "100%", height: "100%" }}>
            <TopLogoNisekoGolfEstates />
            {/* <Reel
                folder="static/contents/master-plan/renders"
                amount={120}
                qualitySteps={1}
                defaultControlsPart={0.075}
                stops={masterPlanStops(
                    openZone5,
                    setModalContent,
                )}
                overlay_elements={null} // Soon
                startFrame={startFrame}
            /> */}

            <Frame
                image={getLanguageFrame()}
                wrapper={
                    <SvgAreas
                        areas={[
                            {
                                Polygon: Zone5_21,
                                className: "golf-estates-area",
                                infobox: null,
                                onClick: () => navigate(`/${languageCode}/golf-estates`),
                                isInfoboxOnMobile: false,
                            },
                        ]}
                    />
                }
            />

            {/* <BackButton
                onClick={() =>
                    changeContent(<Buraydah />, {
                        button: "menu_buraydah",
                        element: null,
                        head_title: null,
                    })
                }
            /> */}
        </div>
    );
}
