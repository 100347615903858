//stop frame 6
import { ReactComponent as LandPlot_6_3 } from "./../land_plots/6/land_plot_block_3.svg";
import { ReactComponent as LandPlot_6_4 } from "./../land_plots/6/land_plot_block_4.svg";
import { ReactComponent as LandPlot_6_5 } from "./../land_plots/6/land_plot_block_5.svg";
import { ReactComponent as LandPlot_6_6 } from "./../land_plots/6/land_plot_block_6.svg";
import { ReactComponent as LandPlot_6_7 } from "./../land_plots/6/land_plot_block_7.svg";
import { ReactComponent as LandPlot_6_8 } from "./../land_plots/6/land_plot_block_8.svg";
import { ReactComponent as LandPlot_6_9 } from "./../land_plots/6/land_plot_block_9.svg";
import { ReactComponent as LandPlot_6_10 } from "./../land_plots/6/land_plot_block_10.svg";
import { ReactComponent as LandPlot_6_11 } from "./../land_plots/6/land_plot_block_11.svg";
import { ReactComponent as LandPlot_6_12 } from "./../land_plots/6/land_plot_block_12.svg";
import { ReactComponent as LandPlot_6_13 } from "./../land_plots/6/land_plot_block_13.svg";
import { ReactComponent as LandPlot_6_14 } from "./../land_plots/6/land_plot_block_14.svg";
import { ReactComponent as LandPlot_6_15 } from "./../land_plots/6/land_plot_block_15.svg";
import { ReactComponent as LandPlot_6_16 } from "./../land_plots/6/land_plot_block_16.svg";
import { ReactComponent as LandPlot_6_17 } from "./../land_plots/6/land_plot_block_17.svg";
import { ReactComponent as LandPlot_6_18 } from "./../land_plots/6/land_plot_block_18.svg";
import { ReactComponent as LandPlot_6_19 } from "./../land_plots/6/land_plot_block_19.svg";
import { ReactComponent as LandPlot_6_20 } from "./../land_plots/6/land_plot_block_20.svg";
import { ReactComponent as LandPlot_6_21 } from "./../land_plots/6/land_plot_block_21.svg";
import { ReactComponent as LandPlot_6_22 } from "./../land_plots/6/land_plot_block_22.svg";
import { ReactComponent as LandPlot_6_23 } from "./../land_plots/6/land_plot_block_23.svg";
import { ReactComponent as LandPlot_6_24 } from "./../land_plots/6/land_plot_block_24.svg";
import { ReactComponent as LandPlot_6_25 } from "./../land_plots/6/land_plot_block_25.svg";
import { ReactComponent as LandPlot_6_26 } from "./../land_plots/6/land_plot_block_26.svg";


//imgs
import LotImg1 from "../../../../../static/images/contents/golf-estates/lots/1.jpg";    
import LotImg2 from "../../../../../static/images/contents/golf-estates/lots/2.jpg";
import LotImg3 from "../../../../../static/images/contents/golf-estates/lots/3.jpg";
import LotImg5 from "../../../../../static/images/contents/golf-estates/lots/5.jpg";
import LotImg6 from "../../../../../static/images/contents/golf-estates/lots/6.jpg";
import LotImg7 from "../../../../../static/images/contents/golf-estates/lots/7.jpg";
import LotImg8 from "../../../../../static/images/contents/golf-estates/lots/8.jpg";
import LotImg9 from "../../../../../static/images/contents/golf-estates/lots/9.jpg";
import LotImg10 from "../../../../../static/images/contents/golf-estates/lots/10.jpg";
import LotImg11 from "../../../../../static/images/contents/golf-estates/lots/11.jpg";
import LotImg12 from "../../../../../static/images/contents/golf-estates/lots/12.jpg";
import LotImg15 from "../../../../../static/images/contents/golf-estates/lots/15.jpg";
import LotImg16 from "../../../../../static/images/contents/golf-estates/lots/16.jpg";
import LotImg17 from "../../../../../static/images/contents/golf-estates/lots/17.jpg";
import LotImg18 from "../../../../../static/images/contents/golf-estates/lots/18.jpg";
import LotImg19 from "../../../../../static/images/contents/golf-estates/lots/19.jpg";
import LotImg20 from "../../../../../static/images/contents/golf-estates/lots/20.jpg";
import LotImg21 from "../../../../../static/images/contents/golf-estates/lots/21.jpg";
import LotImg22 from "../../../../../static/images/contents/golf-estates/lots/22.jpg";
import LotImg23 from "../../../../../static/images/contents/golf-estates/lots/23.jpg";
import LotImg25 from "../../../../../static/images/contents/golf-estates/lots/25.jpg";
import LotImg26 from "../../../../../static/images/contents/golf-estates/lots/26.jpg";
import LotImg27 from "../../../../../static/images/contents/golf-estates/lots/27.jpg";
import LotImg28 from "../../../../../static/images/contents/golf-estates/lots/28.jpg";

export const areas2 = [
    {
        Polygon: LandPlot_6_3,
        className: null,
        label: {
            coords: [1267, 397],
        },
        infobox: {
            type: "land_info",
            index: 1,
            lot: "1",
            total_lot_size: "619 ",
            lot_size_tsubo: "187.25",
            forest_area: "337 ",
            buildable_area: "198 ",
            max_suggested_villa_floor_area: "476-595 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg1,
            price: "0",
        },
        onClick: {
            // action: "open_contact_form",
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_4,
        className: null,
        label: {
            coords: [1136, 353],
        },
        infobox: {
            type: "land_info",
            index: 2,
            lot: "2",
            total_lot_size: "612",
            lot_size_tsubo: "185.13",
            forest_area: "350 ",
            buildable_area: "198",
            max_suggested_villa_floor_area: "500-620 ",
            description:
                "*2: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg2,
            price: "0",
        },
        onClick: {
            // action: "open_contact_form",
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_5,
        className: null,
        label: {
            coords: [1017, 307],
        },
        infobox: {
            type: "land_info",
            index: 3,
            lot: "3",
            total_lot_size: "612 ",
            lot_size_tsubo: "185.13",
            forest_area: "335 ",
            buildable_area: "193 ",
            max_suggested_villa_floor_area: "465-580 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg3,
            price: "¥130,000,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_6,
        className: null,
        label: {
            coords: [897, 296],
        },
        infobox: {
            type: "land_info",
            index: 5,
            lot: "5",
            total_lot_size: "667 ",
            lot_size_tsubo: "201.77",
            forest_area: "273 ",
            buildable_area: "286 ",
            max_suggested_villa_floor_area: "687-858 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg5,
            price: "¥141,230,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_7,
        className: null,
        label: {
            coords: [804, 246],
        },
        infobox: {
            type: "land_info",
            index: 6,
            lot: "6",
            total_lot_size: "662 ",
            lot_size_tsubo: "200.26",
            forest_area: "307 ",
            buildable_area: "254 ",
            max_suggested_villa_floor_area: "611-763 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg6,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_8,
        className: null,
        label: {
            coords: [715, 212],
        },
        infobox: {
            type: "land_info",
            index: 7,
            lot: "7",
            total_lot_size: "666 ",
            lot_size_tsubo: "201.47",
            forest_area: "354 ",
            buildable_area: "222 ",
            max_suggested_villa_floor_area: "534-667 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg7,
            price: "¥151,100,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_9,
        className: null,
        label: {
            coords: [611, 222],
        },
        infobox: {
            type: "land_info",
            index: 8,
            lot: "8",
            total_lot_size: "611 ",
            lot_size_tsubo: "184.83",
            forest_area: "281 ",
            buildable_area: "240 ",
            max_suggested_villa_floor_area: "576-720 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg8,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_10,
        className: null,
        label: {
            coords: [541, 318],
        },
        infobox: {
            type: "land_info",
            index: 9,
            lot: "9",
            total_lot_size: "611 ",
            lot_size_tsubo: "184.83",
            forest_area: "325 ",
            buildable_area: "243 ",
            max_suggested_villa_floor_area: "585-731 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg9,
            price: "¥221,800,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_11,
        className: null,
        label: {
            coords: [599, 397],
        },
        infobox: {
            type: "land_info",
            index: 10,
            lot: "10",
            total_lot_size: "619 ",
            lot_size_tsubo: "187.25",
            forest_area: "277 ",
            buildable_area: "249 ",
            max_suggested_villa_floor_area: "599-748 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg10,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_12,
        className: null,
        label: {
            coords: [661, 471],
        },
        infobox: {
            type: "land_info",
            index: 11,
            lot: "11",
            total_lot_size: "629 ",
            lot_size_tsubo: "190.27",
            forest_area: "276 ",
            buildable_area: "251 ",
            max_suggested_villa_floor_area: "602-753 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg11,
            price: "¥218,800,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_13,
        className: null,
        label: {
            coords: [724, 551],
        },
        infobox: {
            type: "land_info",
            index: 12,
            lot: "12",
            total_lot_size: "633",
            lot_size_tsubo: "191.48",
            forest_area: "274 ",
            buildable_area: "255 ",
            max_suggested_villa_floor_area: "614-767 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg12,
            price: "¥232,000,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_14,
        className: null,
        label: {
            coords: [786, 639],
        },
        infobox: {
            type: "land_info",
            index: 15,
            lot: "15",
            total_lot_size: "657 ",
            lot_size_tsubo: "198.74",
            forest_area: "289 ",
            buildable_area: "265 ",
            max_suggested_villa_floor_area: "638-797 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg15,
            price: "¥238,000,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_15,
        className: null,
        label: {
            coords: [867, 720],
        },
        infobox: {
            type: "land_info",
            index: 16,
            lot: "16",
            total_lot_size: "671 ",
            lot_size_tsubo: "202.98",
            forest_area: "291 ",
            buildable_area: "269 ",
            max_suggested_villa_floor_area: "646-807 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg16,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_16,
        className: null,
        label: {
            coords: [997, 775],
        },
        infobox: {
            type: "land_info",
            index: 17,
            lot: "17",
            total_lot_size: "615 ",
            lot_size_tsubo: "186.04",
            forest_area: "271 ",
            buildable_area: "247 ",
            max_suggested_villa_floor_area: "593-741 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg17,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_17,
        className: null,
        label: {
            coords: [1115, 839],
        },
        infobox: {
            type: "land_info",
            index: 18,
            lot: "18",
            total_lot_size: "637 ",
            lot_size_tsubo: "192.69",
            forest_area: "273 ",
            buildable_area: "255 ",
            max_suggested_villa_floor_area: "612-765 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg18,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_18,
        className: null,
        label: {
            coords: [1206, 926],
        },
        infobox: {
            type: "land_info",
            index: 19,
            lot: "19",
            total_lot_size: "632 ",
            lot_size_tsubo: "191.18",
            forest_area: "262 ",
            buildable_area: "257 ",
            max_suggested_villa_floor_area: "617-771 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg19,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_19,
        className: null,
        label: {
            coords: [1296, 1027],
        },
        infobox: {
            type: "land_info",
            index: 20,
            lot: "20",
            total_lot_size: "627 ",
            lot_size_tsubo: "189.67",
            forest_area: "370 ",
            buildable_area: "166 ",
            max_suggested_villa_floor_area: "400-499 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg20,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_20,
        className: null,
        label: {
            coords: [1347, 701],
        },
        infobox: {
            type: "land_info",
            index: 21,
            lot: "21",
            total_lot_size: "606",
            lot_size_tsubo: "183.32",
            forest_area: "370 ",
            buildable_area: "245",
            max_suggested_villa_floor_area: "400-499 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg21,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_21,
        className: null,
        label: {
            coords: [1196, 634],
        },
        infobox: {
            type: "land_info",
            index: 22,
            lot: "22",
            total_lot_size: "607 ",
            lot_size_tsubo: "183.62",
            forest_area: "262 ",
            buildable_area: "247 ",
            max_suggested_villa_floor_area: "594-743 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg22,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_22,
        className: null,
        label: {
            coords: [1036, 565],
        },
        infobox: {
            type: "land_info",
            index: 23,
            lot: "23",
            total_lot_size: "606 ",
            lot_size_tsubo: "183.32",
            forest_area: "275 ",
            buildable_area: "242 ",
            max_suggested_villa_floor_area: "581-726 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg23,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_23,
        className: null,
        label: {
            coords: [920, 468],
        },
        infobox: {
            type: "land_info",
            index: 25,
            lot: "25",
            total_lot_size: "650 ",
            lot_size_tsubo: "196.63",
            forest_area: "358 ",
            buildable_area: "202 ",
            max_suggested_villa_floor_area: "487-608 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg25,
            price: "¥147,500,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_24,
        className: null,
        label: {
            coords: [1067, 474],
        },
        infobox: {
            type: "land_info",
            index: 26,
            lot: "26",
            total_lot_size: "466 ",
            lot_size_tsubo: "140.97",
            forest_area: "177 ",
            buildable_area: "202 ",
            max_suggested_villa_floor_area: "486-607 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg26,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_25,
        className: null,
        label: {
            coords: [1215, 525],
        },
        infobox: {
            type: "land_info",
            index: 27,
            lot: "27",
            total_lot_size: "458",
            lot_size_tsubo: "138.55",
            forest_area: "335 ",
            buildable_area: "202",
            max_suggested_villa_floor_area: "476-595 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg27,
            price: "¥95,000,000",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
    {
        Polygon: LandPlot_6_26,
        className: null,
        label: {
            coords: [1370, 582],
        },
        infobox: {
            type: "land_info",
            index: 28,
            lot: "28",
            total_lot_size: "458 ",
            lot_size_tsubo: "138.55",
            forest_area: "167 ",
            buildable_area: "202 ",
            max_suggested_villa_floor_area: "486-607 ",
            description:
                "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
            status: "",
            image: LotImg28,
            price: "0",
        },
        onClick: {
            action: "open_enquire_window",
        },
    },
];