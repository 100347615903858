export const translationsJp = {
    //no translation needed
    haru_no_taki: "春の滝",
    niseko_village_golf_course: "ニセコビレッジゴルフコース",
    ace_gondola: "エースゴンドラ",
    hirafu_village: "ひらふビレッジ",
    kutchan_town: "俱知安町",

    back: "戻る",
    lot_size_sqm: "分譲敷地面積（㎡）",
    lot_size_tsubo: "分譲敷地面積（坪）",
    buildable_area: "建築可能面積",
    sold: "売却済み",
    held: "商談中",
    available: "分譲中",
    "phase-2": "フェーズ2",
    english: "English",
    japanese: "日本語",
    chinese_traditional: "中文（繁體）",
    chinese_simplified: "中文（简体）",
    enquire_now: "お問合せ",
    full_name: "お名前",
    email: "メールアドレス",
    phone_number: "電話番号",
    country: "国",
    send: "送信",
    total_lot_size: "総敷地面積",
    forest_area: "森林面積",
    max_villa_floor_area: "最大延床面積",

};