import "pannellum";

import "./VR.scss";
import { useEffect } from "react";
import { VRExit } from "../../modal/Modal";

// import crossImg from "../../../static/images/Close.svg"

export default function VR({
     id, 
     panorama, 
     closeFunc, 
     secondPanorama, 
     thirdPanorama, 
     fourthPanorama,
     fifthPanorama,
     sixthPanorama,
     panoramaName,
    }) {
    // useEffect(() => {
    //     if (id && panorama) {
    //         window.pannellum.viewer(id, {
    //             autoLoad: true,
    //             showControls: false,
    //             type: "equirectangular",
    //             panorama: panorama,
    //         });
    //     }
    // }, [id, panorama]);

    useEffect(() => {
        if (id && panorama) {
            window.pannellum.viewer(id, {
                autoLoad: true,
                showControls: false,  
                sceneFadeDuration: 1000,
                "default": {
                    "firstScene": panoramaName,
                },
            
                "scenes": {
                    "panorama": {
                        // "hfov": 110,
                        // "pitch": -3,
                        // "yaw": 117,
                        type: "equirectangular",
                        panorama: panorama,
                        "hotSpots": [
                            {
                                "pitch": -2.1,
                                "yaw": 136.9,
                                "type": "scene",
                                "sceneId": "secondPanorama",
                            }
                        ]
                    },
            
                    "secondPanorama": {
                        "type": "equirectangular",
                        panorama: secondPanorama,
                        "hotSpots": [
                            {
                                "pitch": -1.5,
                                "yaw": -40.1,
                                "type": "scene",
                                "sceneId": "panorama",
                                // "targetYaw": -23,
                                // "targetPitch": 2
                            },
                            {
                                "pitch": -4.2,
                                "yaw": 1,
                                "type": "scene",
                                "sceneId": "thirdPanorama",
                            },
                            {
                                "pitch": -2,
                                "yaw": 120,
                                "type": "scene",
                                "sceneId": "fifthPanorama",
                            },
                        ]
                    },

                    "thirdPanorama": {
                        "type": "equirectangular",
                        panorama: thirdPanorama,
                        "hotSpots": [
                            {
                                "pitch": -3.5,
                                "yaw": -115,
                                "type": "scene",
                                "sceneId": "secondPanorama",
                            },
                            {
                                "pitch": -3.5,
                                "yaw": 105,
                                "type": "scene",
                                "sceneId": "fourthPanorama",
                            },
                        ]
                    },

                    "fourthPanorama": {
                        "type": "equirectangular",
                        panorama: fourthPanorama,
                        "hotSpots": [
                            {
                                "pitch": -2,
                                "yaw": -75,
                                "type": "scene",
                                "sceneId": "thirdPanorama",
                            }
                        ]
                    },

                    "fifthPanorama": {
                        "type": "equirectangular",
                        panorama: fifthPanorama,
                        "hotSpots": [
                            {
                                "pitch": -2,
                                "yaw": -60,
                                "type": "scene",
                                "sceneId": "secondPanorama",
                            },
                            {
                                "pitch": -2,
                                "yaw": 130,
                                "type": "scene",
                                "sceneId": "sixthPanorama",
                            },
                        ]
                    },

                    "sixthPanorama": {
                        "type": "equirectangular",
                        panorama: sixthPanorama,
                        "hotSpots": [
                            {
                                "pitch": -2,
                                "yaw": -20,
                                "type": "scene",
                                "sceneId": "fifthPanorama",
                            }
                        ]
                    },
                }
            });
        }
    }, [id, panorama]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(event.key === "Escape"){
                closeFunc();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [closeFunc]);

    return (
        <>
            <VRExit closeFunc={closeFunc} />
            <div className="vr" id={id} />
        </>
    );
}
