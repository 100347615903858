export const translationsEn = {
    //no translation needed
    haru_no_taki: "Haru no Taki",
    niseko_village_golf_course: "Niseko Village Golf Course",
    ace_gondola: "Ace Gondola",
    hirafu_village: "Hirafu Village",
    kutchan_town: "Kutchan Town",

    back: "Back",
    lot_size_sqm: "Lot Size (sqm)",
    lot_size_tsubo: "Lot Size (tsubo)",
    buildable_area: "Buildable Area (sqm)",
    sold: "SOLD",
    held: "HELD",
    available: "Available",
    "phase-2": "Phase 2",
    english: "English",
    japanese: "日本語",
    chinese_traditional: "中文（繁體）",
    chinese_simplified: "中文（简体）",
    enquire_now: "Enquire Now",
    full_name: "Full name",
    email: "Email",
    phone_number: "Phone Number",
    country: "Country",
    send: "Send",
    total_lot_size: "Total lot size",
    forest_area: "Forest area",
    max_villa_floor_area: "Max villa floor area",

};
