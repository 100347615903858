import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";
import React, {useEffect, useState} from "react";

import VR_C1 from "../../../../static/images/contents/golf-estates/vr/С1_360_Full.jpg";
import VR_C2 from "../../../../static/images/contents/golf-estates/vr/С2_360_Full.jpg";
import VR_C3 from "../../../../static/images/contents/golf-estates/vr/С3_360_Full.jpg";
import VR_C4 from "../../../../static/images/contents/golf-estates/vr/С4_360_Full.jpg";
import VR_C5 from "../../../../static/images/contents/golf-estates/vr/С5_360_Full.jpg";
import VR_C6 from "../../../../static/images/contents/golf-estates/vr/С6_360_Full.jpg";
import VR from "../../../../components/main/vr/VR";

//stop frame 6
import { ReactComponent as VR360_6_1 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_1.svg";
import { ReactComponent as VR360_6_2 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_2.svg";
import { ReactComponent as VR360_6_3 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_3.svg";
import { ReactComponent as VR360_6_4 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_4.svg";
import { ReactComponent as VR360_6_5 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_5.svg";
import { ReactComponent as VR360_6_6 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_6.svg";

//stop frame 27
import { ReactComponent as VR360_27_1 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_1.svg";
import { ReactComponent as VR360_27_2 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_2.svg";
import { ReactComponent as VR360_27_3 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_3.svg";
import { ReactComponent as VR360_27_4 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_4.svg";
import { ReactComponent as VR360_27_5 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_5.svg";
import { ReactComponent as VR360_27_6 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_6.svg";

//stop frame 62
import { ReactComponent as VR360_62_1 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_1.svg";
import { ReactComponent as VR360_62_2 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_2.svg";
import { ReactComponent as VR360_62_3 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_3.svg";
import { ReactComponent as VR360_62_4 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_4.svg";
import { ReactComponent as VR360_62_5 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_5.svg";
import { ReactComponent as VR360_62_6 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_6.svg";

//stop frame 89
import { ReactComponent as VR360_89_1 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_1.svg";
import { ReactComponent as VR360_89_2 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_2.svg";
import { ReactComponent as VR360_89_3 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_3.svg";
import { ReactComponent as VR360_89_4 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_4.svg";
import { ReactComponent as VR360_89_5 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_5.svg";
import { ReactComponent as VR360_89_6 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_6.svg";

import { areas2 } from "./areas/areas2";
import { areas33 } from "./areas/areas33";
import { areas61 } from "./areas/areas61";
import { areas91 } from "./areas/areas91";

export default function useLandPlots (setModalContent, setEnquireWindowContent,) {

    const [areasDB, setAreasDB] = useState([]);
    
    useEffect(() => {
        fetch('https://niseko-api.visengine.app/areas')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAreasDB(data);
            })
            .catch(error => console.error('Error of getting user number', error))
    }, []);
    
    const mergeAreasWithData = (areas) => {

        return areas.map(area => {
            const matchedData = areasDB.find(d => d.index === area.infobox.index);

            if (matchedData) {
                return {
                    ...area,
                    infobox: {
                        ...area.infobox,
                        status: matchedData.availability,
                        price: matchedData.price,
                    },
                };
            }

            return area;
        });
    };

    const areasMerged2 = mergeAreasWithData(areas2);
    const areasMerged33 = mergeAreasWithData(areas33);
    const areasMerged61 = mergeAreasWithData(areas61);
    const areasMerged91 = mergeAreasWithData(areas91);
    
    return [
    //index 6
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={areasMerged2}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_6_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"panorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_6_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"secondPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_6_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"thirdPanorama"}  
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_6_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fourthPanorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_6_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fifthPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_6_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"sixthPanorama"}
                                    />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    //index 27
    {
        index: 33,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={areasMerged33}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_27_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"panorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_27_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"secondPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_27_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"thirdPanorama"}  
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_27_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fourthPanorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_27_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fifthPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_27_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"sixthPanorama"}
                                    />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    //index 62
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={areasMerged61}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_62_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"panorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_62_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"secondPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_62_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"thirdPanorama"}  
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_62_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fourthPanorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_62_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fifthPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_62_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"sixthPanorama"}
                                    />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    //index 89
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={areasMerged91}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_89_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"panorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_89_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"secondPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_89_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"thirdPanorama"}  
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_89_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fourthPanorama"}
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_89_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"fifthPanorama"} 
                                    />
                                );
                            },
                        },
                        {
                            Element: VR360_89_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR
                                     id="walking-area" 
                                     panorama={VR_C1} 
                                     secondPanorama={VR_C2} 
                                     thirdPanorama={VR_C3}
                                     fourthPanorama={VR_C4}
                                     fifthPanorama={VR_C5}
                                     sixthPanorama={VR_C6}
                                     panoramaName={"sixthPanorama"}
                                    />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
]};
