import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import "./Menu.scss";
import "./Adaptations.scss";
import LanguageButton from "./language-button/LanguageButton";

import ArrowSelect from "../../../static/images/arrow_select.svg"

import { useLocation } from "react-router-dom";
import triangle from "../../../static/images/triangle.svg";


export default function Menu({
    buttons,
    content,
    changeContent,
    contentClicked,
    setEnquireWindowContent,
}) {

    const location = useLocation();

    const pathAfterLang = location.pathname.split('/').slice(2).join('/');

    const languageCode = location.pathname.split('/')[1];

    const getLanguage = () => {
        if (languageCode === "en") {
            return "English";
        } else if (languageCode === "jp") {
            return "日本語";
        } else if (languageCode === "ch1") {
            return "中文 繁體";
        } else if (languageCode === "ch2") {
            return "中文 简体";
        }
    };

    const [modalClicked, setModalClicked] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(getLanguage());
    const [currentExpanded, setCurrentExpanded] = useState(null);
    const menuRef = useRef(null);

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setModalClicked(false);
        // setEnquireWindowContent(null);
    };

    const modalClick = () => {
        setModalClicked((prev) => !prev);
    }

    useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
        setModalClicked(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [menuRef]);

    const hideExpanded = () => setCurrentExpanded(null);
    useEffect(() => {
        hideExpanded();
    }, [contentClicked]);

    return (
        <>
            <div className="menu">
                <div className="lang-menu" ref={menuRef}>
                    <div
                     className={`select${modalClicked ? " active" : ""}`} 
                     onClick={modalClick}
                    >
                        <span>{selectedLanguage}</span>
                        <img className="arrow-select" src={ArrowSelect} alt="" />
                    </div>
                    {modalClicked ? 
                        <>
                            <img className="triangle" src={triangle}></img>
                            <div className="select-dropdown">
                                <Link
                                    to={`/en/${pathAfterLang}`}
                                    className="dropdown-element"
                                    onClick={() => handleLanguageChange("English")}
                                >
                                    <span>English</span>
                                </Link>
                                <Link
                                    to={`/jp/${pathAfterLang}`}
                                    className="dropdown-element"
                                    onClick={() => handleLanguageChange("日本語")}
                                >
                                    <span>日本語</span>
                                </Link>
                                <Link
                                    to={`/ch1/${pathAfterLang}`}
                                    className="dropdown-element"
                                    onClick={() => handleLanguageChange("中文 繁體")}
                                >
                                    <span>中文 繁體</span>
                                </Link>
                                <Link
                                    to={`/ch2/${pathAfterLang}`}
                                    className="dropdown-element"
                                    onClick={() => handleLanguageChange("中文 简体")}
                                >
                                    <span>中文 简体</span>
                                </Link>
                            </div>
                        </>
                        :
                        <>
                        </>
                    }
                </div>
            </div>
        </>
    );
}
