import { useTranslation } from "react-i18next";
import "./SvgLabel.scss";

const calculateTextWidth = (text, fontSize, fontFamily, fontWeight) => {
    const font = fontSize + "px " + fontFamily;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontWeight} ${font}`;
    return context.measureText(text).width;
};

export default function SvgLabel({ coords, text = "", status, width, isHovered }) {
    const [t] = useTranslation();
    const labelText = t(status).toUpperCase();

    const indexTextWidth = calculateTextWidth(text, 27, "Inter", 400);
    const textWidth = calculateTextWidth(labelText, 13, "Inter", 700);
    const rectWidth = textWidth + 9;

    const classNameWithStatus = status.toLowerCase().replace(" ", "-");

    return (
        <g className="svg-label">
            <text
                className={
                    "svg-label-index-text svg-label-index-text-" +
                    classNameWithStatus
                }
                x={coords[0] - indexTextWidth / 2}
                y={coords[1] + 5}
            >
                {text}
            </text>
            {status === "held" || status === "sold" ? (
                <>
                    <rect
                        className={
                            "svg-label-background svg-label-background-" +
                            classNameWithStatus
                        }
                        x={coords[0] - rectWidth / 2}
                        y={coords[1] + 10}
                        width={rectWidth}
                        height="20"
                    />
                    <text
                        className={
                            "svg-label-text svg-label-text-" +
                            classNameWithStatus
                        }
                        x={coords[0] - textWidth / 2}
                        y={coords[1] + 24.7}
                    >
                        {labelText}
                    </text>
                </>
            ) : (
                <>
                    {isHovered ? (
                        <>
                            <rect
                                className={
                                    "svg-label-background svg-label-background-" +
                                    classNameWithStatus
                                }
                                x={coords[0] - rectWidth / 2}
                                y={coords[1] + 10}
                                width={rectWidth}
                                height="20"
                                style={{opacity: "1"}}
                            />
                            <text
                                className={
                                    "svg-label-text svg-label-text-" +
                                    classNameWithStatus
                                }
                                x={coords[0] - textWidth / 2}
                                y={coords[1] + 24.7}
                                style={{opacity: "1"}}
                            >
                                {labelText.replace("-", " ")}
                            </text>
                        </>
                    ) : (
                        <>
                            <rect
                                className={
                                    "svg-label-background svg-label-background-" +
                                    classNameWithStatus
                                }
                                x={coords[0] - rectWidth / 2}
                                y={coords[1] + 10}
                                width={rectWidth}
                                height="20"
                                style={{opacity: "0"}}
                            />
                            <text
                                className={
                                    "svg-label-text svg-label-text-" +
                                    classNameWithStatus
                                }
                                x={coords[0] - textWidth / 2}
                                y={coords[1] + 24.7}
                                style={{opacity: "0"}}
                            >
                                {labelText.replace("-", " ")}
                            </text>
                        </>
                    )}
                </>
            )}
        </g>
    );
}
