export const translationsCh1 = {
    //no translation needed
    haru_no_taki: "春之瀑布",
    niseko_village_golf_course: "二世谷高爾夫球場",
    ace_gondola: "Ace 貢多拉",
    hirafu_village: "比羅夫村",
    kutchan_town: "俱知安鎮",

    back: "往前",
    lot_size_sqm: "土地面積（平方米）",
    lot_size_tsubo: "土地面積（坪）",
    buildable_area: "可建築面積",
    sold: "已售出",
    held: "保留",
    available: "銷售中",
    "phase-2": "第二期",
    english: "English",
    japanese: "日本語",
    chinese_traditional: "中文（繁體）",
    chinese_simplified: "中文（简体）",
    enquire_now: "諮詢",
    full_name: "名字",
    email: "電子郵件",
    phone_number: "電話號碼",
    country: "國籍",
    send: "寄出",
    total_lot_size: "土地大小",
    forest_area: "森林面積",
    max_villa_floor_area: "最大別墅建築面積",

};